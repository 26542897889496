import { Paper, styled } from "@mui/material";

export const isEmail = (email: string) =>
  /^([^\s\@])+\@(([^\s\@\.])+\.)+([^\s\.]{2,})+$/gmi.test(email)

export type requestStateType = 'notstarted' | 'pending' | 'verifying' | 'ok' | 'error'

export const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 40,
    lineHeight: '40px',
  }));

export const gateUsers = process.env.REACT_APP_GATE_USERS ? process.env.REACT_APP_GATE_USERS === "true" : false