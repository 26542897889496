import { makeObservable, observable, runInAction } from "mobx";


export enum GpsState {
    OFF = 0,
    ON = 1
}

export enum DisplayPowerMode {
    Unknown = -1,
    Off = 0,
    Doze = 1,
    On = 2,
    DozeSuspend = 3
}

export enum WebcamState {
    Off = 0,
    On = 1
}

export enum GnssPowerMode {
    Unknown = -1,
    Off = 0,
    On = 1
}

export enum ProcessState {
    STOPPED,
    STARTING,
    RUNNING,
    STOPPING,
    FAULTED    
}

export class Process {
    @observable state: ProcessState = ProcessState.STOPPED
}

export interface EmulatorClipboard {
    text: string
}

export class HardwareState {
    static readonly webcamWidth = 480
    static readonly webcamHeight = 480
    static readonly frontWebcamId = "user"
    static readonly backWebcamId = "environment"
    @observable displayPowerMode: DisplayPowerMode = DisplayPowerMode.Unknown;
    @observable frontWebcamState: WebcamState = WebcamState.Off;
    @observable backWebcamState: WebcamState = WebcamState.Off;
    @observable activeMicrophones: string[] = [];
    @observable openedMicrophones: string[] = [];
    @observable closedMicrophones: string[] = [];
    @observable gnssState: GnssPowerMode = GnssPowerMode.Unknown;
    @observable process: Process = { state: ProcessState.STOPPED};
    @observable clipboard: EmulatorClipboard = { text: ''}

    constructor() {
        makeObservable(this);
    }

    update(newHardwareState: HardwareState) {
        runInAction(() => {
            this.backWebcamState = newHardwareState.backWebcamState ?? this.backWebcamState
            this.frontWebcamState = newHardwareState.frontWebcamState ?? this.frontWebcamState
            this.displayPowerMode = newHardwareState.displayPowerMode ?? this.displayPowerMode
            this.gnssState = newHardwareState.gnssState ?? this.gnssState
            this.process = newHardwareState.process ?? this.process
            this.closedMicrophones = this.activeMicrophones.filter(am => !newHardwareState.activeMicrophones.includes(am));
            this.openedMicrophones = newHardwareState.activeMicrophones.filter(am => !this.activeMicrophones.includes(am));
            this.activeMicrophones = newHardwareState.activeMicrophones ?? this.activeMicrophones
            this.clipboard = newHardwareState.clipboard ?? this.clipboard
        })
    }
}
